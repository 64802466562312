import React  from "react"
import { graphql, Link } from "gatsby"

export default function FaqTemplate({ data }) {
  const { faqs } = data.faq

  return (
    <div>
      <h1>Оллспортс Размеры лицензионных вознаграждений</h1>

      {faqs.map((faq) => (
        <article key={faq.id}>
          <Link state={{ dropdownLink: true }} to={faq.fields.slug}>
            <h2>{faq.frontmatter.title}</h2>
          </Link>
          <small>{faq.frontmatter.date}</small>
          <p>{faq.excerpt}</p>
        </article>
      ))}
    </div>
  )
}

export const pageQuery = graphql`
  query FaqsQuery {
    faq: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/(faq)/" } }) {
      faqs: nodes {
        fields {
          slug
        }
        frontmatter {
          date(fromNow: true)
          title
        }
        excerpt
        id
      }
    }
  }
`
